.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bodyText {
  text-align: justify;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* App.css */
body {
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #f8f9fa;
  padding-top: 60px;
  transition: transform 0.3s ease;
  z-index: 1000;
}

.sidebar .nav-link {
  font-size: 1.1em;
  padding: 10px 20px;
  color: #333;
  cursor: pointer;
}

.sidebar .nav-link:hover {
  background-color: #e9ecef;
}

.content {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 250px;
  transition: margin-left 0.3s ease;
}

.content.with-sidebar {
  margin-left: 250px;
}

.content.without-sidebar {
  margin-left: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header img {
  max-width: 100px;
}

.header h1 {
  margin: 0;
}

.burger-menu {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.show {
    transform: translateX(0);
  }

  .burger-menu {
    display: block;
  }

  .content.with-sidebar {
    margin-left: 0;
  }
}

.main-content {
  padding-top: 20px;
}

footer {
  margin-top: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes space between elements */
  padding: 10px;
}

.logo {
  max-width: 100px;
}

.title {
  flex: 1; /* Allows the heading to take up remaining space */
  text-align: center; /* Centers the heading text */
  margin: 0; /* Remove default margins */
}

.burger-menu {
  margin-left: auto; /* Pushes the burger menu to the far right */
}

.table-section {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
}

.table-section h3 {
  margin-bottom: 1rem;
}